/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 9/19/20, 10:58 AM
 *
 */

import { AxiosResponse } from "axios";
import { UTMLink } from "../typings/api/utm-links";
import APIService from "./api.service";
import AuthService from "./auth.service";

class PushRecordService extends APIService<any> {
    constructor() {
        super("push");
    }
    // async getAll(): Promise<void | AxiosResponse<UTMLink[]>> {
    //     if (await AuthService.canIAccess("ADMIN_DASHBOARD")) {
    //         return super.getAll();
    //     } else {
    //         // if the user doesn't have access to all phone numbers they should see a subset
    //         // alert("sub");
    //         return super.getURL("my-utm-links");

    //         // return [];
    //     }
    // }
    pushNewRecord(record: any, type: "slack" | "monday"): Promise<any> {
        if (type === "slack") {
            return super.postURL("/push/to-slack", { newUser: record });
        } else {
            return super.postURL("/push/to-monday", { newUser: record });
        }
        //  super.post(typeQ, record);
    }
    pushRecordById(id: string, type: "slack" | "monday"): Promise<any> {
        if (type === "slack") {
            return super.postURL("/push/to-slack-by-id", { id: id });
        } else {
            return super.postURL("/push/to-monday-by-id", { id: id });
        }
        //  super.post(typeQ, record);
    }
}

export default new PushRecordService();
