import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { Label } from "./label";
import PopoverComponent from "./popover-component";
import PrintPre from "./print-pre";

// const notificationMethods = [
//     { id: 'email', title: 'Email' },
//     { id: 'sms', title: 'Phone (SMS)' },
//     { id: 'push', title: 'Push notification' },
// ]

export default function RadioButtonNeoGenControlled(props: RadioButtonNeoGenControlledProps) {
    return (
        <div>
            <Label
                text={props.label || ""}
                isError={(props.errors ?? []).length > 0}
                helpDocument={props.helpDocument}
                isRequired={!!props.required}
            />
            <p className="text-sm leading-5 text-gray-500">{props.description}</p>

            <div>
                <fieldset className="mt-4 ">
                    <div
                        className={
                            "grid sm:flex grid-cols-2 gap-x-10 gap-y-5 flex-shrink max-w-full flex-wrap " +
                            ((props.errors ?? []).length > 0 && "border  border-red-500 p-5 rounded-lg")
                        }
                    >
                        {props.options?.map((option) => (
                            <div key={option.id} className="flex items-center ">
                                <input
                                    id={props.id}
                                    name={props.id}
                                    type="radio"
                                    checked={props.value !== undefined && option.id === props.value}
                                    className="focus:ring-indigo-500 h-5 w-5 text-indigo-600 border-gray-300 dark:border-gray-500 ,y-5 dark:bg-gray-800"
                                    onChange={(e) => {
                                        // alert(e.currentTarget.value);
                                        props.onChange(option.id);
                                        props.onChange(
                                            option.id,
                                            props.options?.find((ee) => {
                                                return ee?.id === option.id;
                                            })?.name,
                                        );
                                    }}
                                />
                                <label
                                    htmlFor={option.id}
                                    className="ml-3 block text-sm font-normal tracking-wider text-gray-500 "
                                >
                                    {option.name}
                                </label>
                            </div>
                        ))}
                    </div>
                </fieldset>
            </div>
        </div>
    );
}
type RadioButtonNeoGenControlledProps = {
    onChange: (idx: number | string, text?: string) => void;
    value: number;
    label?: string;
    required?: boolean;
    // name: string;
    errors?: string[];
    description?: string;
    id?: string;
    noSpace?: boolean;
    helpDocument?: string | undefined;
    options: { id: any; name: any }[] | undefined;
};
