import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

//import React, { useState } from "react";
import Swal from "sweetalert2";
import { Entry } from "../../../layout/add-to-list";
import { Fragment, useEffect, useRef, useState } from "react";
import ModalFieldValue from "../../../layout/modal-field-value";
// import PrintPre from "../../../layout/print-pre";
import ModalValueCalculator from "../modals/modal-value-calculator";
import ModalHtmlEditor from "../../../layout/modal-html-editor";
//import ReactQuill from "react-quill";
import { Quill } from "react-quill";
// import { ImageActions } from "@xeger/quill-image-actions";
// import { ImageFormats } from "@xeger/quill-image-formats";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ImageActions } from "@xeger/quill-image-actions/dist/main";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ImageFormats } from "@xeger/quill-image-formats/dist/main";
// import CommandPaletteEnabler, { RuleType } from "../../../layout/command-palette-enabler";
import { useQuery } from "@tanstack/react-query";
import businessRuleGroupService from "../../../services/business-rule-group.service";
import _ from "lodash";
import Badge from "../../../layout/badge";
import { BusinessRuleGroup } from "../../../typings/api/business-rule-group";
import ButtonCreator, { ButtonOptions } from "../../../layout/button-creator";
import Collapsible from "../../../layout/collapsible";
import BadgeDismissible from "../../../layout/badge-dismissible";
import { SaveDetailsType } from "../../../typings/save-details-type";
import RulePicker from "../../business-rules/modals/rule-picker";
import ModalVideoSelector from "../../../layout/modal-video-selector";

// Quill.register("modules/imageActions", ImageActions);
// Quill.register("modules/imageFormats", ImageFormats);

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

// TODO: This should have an internal state of the current item and rules
// If it changes, update the external state.
// Internal state should be set on mount and on change.

type FieldEntryProps = {
    entry: Entry;
    entries: Entry[];
    saveEntries: any;
    idx: number;
    processflowId: number;
};

export function FieldEntry({ entry, entries, saveEntries, idx, processflowId }: FieldEntryProps): JSX.Element {
    const [showEditRules, setShowEditRules] = useState(false);
    const [showAddEntry, setShowAddEntry] = useState(false);
    const [showAddHelp, setShowAddHelp] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [helpEntry, setHelpEntry] = useState<Entry>();
    // const [idx, setIdx] = useState<number>(-1);
    const [field, setField] = useState<string>("");
    const [value, setValue] = useState<string>("");
    const fieldsSet = useRef(false);
    const [button, setButton] = useState<ButtonOptions>();
    const [helpDocument, setHelpDocument] = useState<string>("");
    const [widthOf300Cols, setWidthOf300Cols] = useState<string>("150");
    const [description, setDescription] = useState<string>("");

    const [enabledRules, setEnabledRules] = useState<BusinessRuleGroup[]>([]);
    const [rgAreOr, setRgAreOr] = useState<boolean>(true);

    const allRulesAndGroupsQuery = useQuery(["business-rule-groups"], async () => {
        const response = await businessRuleGroupService.getAllIncludingChildren();
        if (response) {
            return response.data;
        }
    });

    //   const selectionOptions = [
    //     { id: 1, title: "Current Step", description: "Show options from the current step" },
    //     { id: 2, title: "All Steps", description: "Show options from the entire product" }
    //   ];
    //   const andOrOptions = [
    //     { id: 1, title: "All Must Match", description: "Show options from the current step" },
    //     { id: 2, title: "At Least One", description: "Show options from the entire product" }
    //   ];

    function addToList({
        field,
        value,
        description,
        required,
        widthOf300Cols,
        selectBoxEntries,
        helpDocument,
        password,
        ruleGroupsAreOr,
    }: SaveDetailsType) {
        const newEntry: Entry = {
            id: field,
            required: required,
            field: field,
            value: value,
            description: description,
            selectBoxEntries: selectBoxEntries,
            type: entry.type,
            icon: entry.icon,
            widthOf300Cols: widthOf300Cols,
            helpDocument: helpDocument ?? "",
            password: password,
            ruleGroupsAreOr: ruleGroupsAreOr,
        };
        setShowAddEntry(false);
        setShowAddHelp(false);
        saveEntries([...(entries ?? []), newEntry]);
    }

    // useEffect(() => {
    //   if (entry) {
    //     alert(idx);
    //   }

    // }, [entry, idx]);

    useEffect(() => {
        if (entry.type === "button") {
            try {
                setButton(JSON.parse(entry.value));
            } catch (e) {
                console.error(e);
            }
        }
    }, [entry.type, entry.value]);

    // TODO: This doesn't work because it is saving a change of fields rather than a change of rules
    function saveEdit({
        field,
        value,
        description,
        required,
        widthOf300Cols,
        selectBoxEntries,
        helpDocument,
        password,
        ruleGroupsAreOr,
    }: SaveDetailsType) {
        const existing = entries ?? [];
        const newEntry: Entry = {
            id: field,
            field: field,
            value: value,
            required: required,
            description: description,
            selectBoxEntries: selectBoxEntries,
            type: entry.type,
            icon: entry.icon,

            widthOf300Cols: widthOf300Cols,
            ruleGroups: enabledRules ?? [],
            helpDocument: helpDocument ?? "",
            password: password,
            ruleGroupsAreOr: ruleGroupsAreOr ?? true,
        };

        // Replace the item at index idx with the new version
        existing[idx] = newEntry;
        saveEntries([...existing]);
        setShowAddEntry(false);
        setShowAddHelp(false);
    }

    function editRules(entry: Entry) {
        // alert(JSON.stringify(entry));
        setEnabledRules(entry.ruleGroups ?? []);
        setShowEditRules(true);
        setRgAreOr(entry.ruleGroupsAreOr ?? true);
    }

    useEffect(() => {
        if (
            fieldsSet.current &&
            !(_.isEqual(entry?.ruleGroups, enabledRules) || _.isEqual(entry?.ruleGroupsAreOr, rgAreOr))
        ) {
            // setEnabledRules(entry.ruleGroups ?? []);
            const existing = entries ?? [];
            const newEntry: Entry = { ...entry, ruleGroups: enabledRules ?? [], ruleGroupsAreOr: rgAreOr };
            // ruleGroups: enabledRules??[]
            // };
            existing[idx] = newEntry;
            saveEntries([...existing]);
        }
    }, [entry, enabledRules, entries, idx, saveEntries, rgAreOr]);

    useEffect(() => {
        if (entry && !fieldsSet.current) {
            setEnabledRules(entry.ruleGroups ?? []);
            setRgAreOr(entry.ruleGroupsAreOr ?? true);
            fieldsSet.current = true;
            // return;
        }
    }, [entry]);

    // function saveHelp(field: string, valueNew: string, description: string, required: boolean, widthOf300Cols: string, selectBoxEntries: string[], helpDocument: string) {
    // console.error({ field, value, valueNew, description, required, widthOf300Cols, selectBoxEntries, helpDocument });
    // }

    return (
        <div
            key={entry.field}
            className={
                "break-inside-avoid mb-5 relative rounded-lg border border-gray-300 bg-slate-50 dark:bg-gray-800 dark:border-gray-700 px-6 py-2 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
            }
        >
            {/* <hr />
            {
                <PrintPre>
                    {entry}
                </PrintPre>
            } */}
            <div className="flex-shrink-0 text-indigo-400 text-center justify-center flex">
                <span className={entry.icon} />
                <div className="my-auto ml-3 text-sm text-gray-500 inline-block">
                    <Badge color={"blue"} label={entry.type} />
                </div>
            </div>
            <div className="flex-grow min-w-0 ">
                {/* <PrintPre>{entry.ruleGroups?.length}</PrintPre> */}
                <div className="flex w-full gap-5 justify-start">
                    {entry.type === "wysiwyg" && (
                        <div className="inline-block flex-1">
                            <div className="inline-block">
                                <Collapsible linkText="Click here to preview the HTML">
                                    <div dangerouslySetInnerHTML={{ __html: entry.value }} />
                                </Collapsible>
                            </div>
                        </div>
                    )}

                    {(entry.ruleGroups ?? []).length > 0 && (
                        <div className="flex-grow ">
                            {entry.ruleGroups?.map((bg) => {
                                return (
                                    <div
                                        key={bg.id}
                                        className="text-sm my-1  dark:border-gray-600  dark:text-gray-400 inline-block mr-3"
                                    >
                                        {/* <span className="mr-3">Rules:</span> */}

                                        {/* <Badge color={"red"} label={bg.name} /> */}
                                        <Badge color={bg.isQualify ? "green" : "red"} label={bg.name} />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {entry.type !== "wysiwyg" && entry.type !== "button" && (
                        <>
                            <span className="absolute inset-0" aria-hidden="true" />
                            <p className="my-auto text-left flex-shrink text-sm font-medium text-gray-900 dark:text-gray-400">
                                {entry.value}
                            </p>

                            {entry.description && (
                                <p className="my-auto flex-1 text-sm text-gray-500 ">
                                    Description: {entry.description}
                                </p>
                            )}
                        </>
                    )}
                    {entry.type === "button" && (
                        <>
                            {/* <PrintPre>{button}</PrintPre> */}
                            <span className="absolute inset-0" aria-hidden="true" />
                            <p className="my-auto text-left flex-shrink text-sm font-medium text-gray-900 dark:text-gray-400">
                                {button?.label}
                            </p>

                            <p className="my-auto flex-grow text-sm text-gray-500 ">Action Type: {button?.action}</p>
                            <p className="my-auto flex-1 text-sm text-gray-500 ">
                                Size: {button?.size !== "" ? button?.size : "medium"}
                            </p>
                        </>
                    )}
                    {entry.helpDocument && (
                        <BadgeDismissible
                            label="Help Document"
                            className="z-10"
                            onRemove={() => {
                                Swal.fire({
                                    title: "Delete Help Section?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        saveEntries(
                                            entries?.map((_entry: Entry) => {
                                                if (entry.id === _entry.id) {
                                                    _entry.helpDocument = "";
                                                }
                                                return _entry;
                                            }) ?? [],
                                        );
                                    }
                                });
                            }}
                        />
                    )}
                    <Badge
                        color={!entry.required ? "green" : "red"}
                        label={entry.required ? "Required" : "Optional"}
                        className="my-auto"
                    />

                    {/* <p className={"my-auto flex-shrink justify-end text-sm " + (entry.required ? "text-red-500 " : "text-green-500")}>{entry.required ? "Required" : "Optional"}</p> */}
                </div>
            </div>
            <div className="mt-5 flex lg:mt-0 lg:ml-4">
                <Menu as="div" className="relative inline-block text-left float-right">
                    <div>
                        <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 dark:border-gray-700 text-sm font-medium text-gray-700 dark:text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                            Options
                            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                        </Menu.Button>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 dark:text-gray-400 ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                            <div className="py-1">
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            className={classNames(
                                                active
                                                    ? "bg-gray-100 text-gray-900 dark:text-gray-400  dark:bg-gray-900"
                                                    : "text-gray-700 dark:text-gray-400 ",
                                                "block px-4 py-2 text-sm w-full text-left",
                                            )}
                                            onClick={() => {
                                                setValue(entry.value);
                                                setDescription(entry.description);
                                                setField(entry.field);
                                                setIsEdit(true);
                                                setShowAddEntry(true);
                                            }}
                                        >
                                            Edit
                                        </button>
                                    )}
                                </Menu.Item>
                                {/* <Menu.Item>
                  {({
                    active
                  }) => <button
                    className={
                      classNames(
                        active ?
                          "bg-gray-100 text-gray-900 dark:text-gray-400  dark:bg-gray-900" :
                          "text-gray-700 dark:text-gray-400 ",
                        "block px-4 py-2 text-sm w-full text-left"
                      )
                    }
                    onClick={() => {

                      console.log(entry);
                    }}>

                  </button>}
                </Menu.Item> */}
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            className={classNames(
                                                active
                                                    ? "bg-gray-100 text-gray-900 dark:text-gray-400  dark:bg-gray-900"
                                                    : "text-gray-700 dark:text-gray-400 ",
                                                "block px-4 py-2 text-sm w-full text-left",
                                            )}
                                            onClick={() => {
                                                // TODO: Not right
                                                // setProcessForRule(idx);
                                                editRules(entry);
                                            }}
                                        >
                                            Rules
                                        </button>
                                    )}
                                </Menu.Item>

                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            className={classNames(
                                                active
                                                    ? "bg-gray-100 text-gray-900 dark:text-gray-400  dark:bg-gray-900"
                                                    : "text-gray-700 dark:text-gray-400 ",
                                                "block px-4 py-2 text-sm w-full text-left",
                                            )}
                                            onClick={() => {
                                                const helpEntryNew = _.cloneDeep(entry);
                                                helpEntryNew.field = field;
                                                setHelpEntry(helpEntryNew);
                                                setIsEdit(true);
                                                setField(entry.field);
                                                setValue(entry.value ?? "");
                                                setHelpDocument(entry.helpDocument ?? "");
                                                // setEntry({..entry, field: "abc123"});
                                                // setField("Help Document For "+entry.value);
                                                setShowAddHelp(true);
                                                // editRules(entry);
                                            }}
                                        >
                                            Edit Help Section
                                        </button>
                                    )}
                                </Menu.Item>

                                <Menu.Item>
                                    {({ active }) => (
                                        <a
                                            href="#"
                                            onClick={() => {
                                                Swal.fire({
                                                    title: "Are you sure?",
                                                    text: "You won't be able to revert this!",
                                                    icon: "warning",
                                                    showCancelButton: true,
                                                    confirmButtonColor: "#3085d6",
                                                    cancelButtonColor: "#d33",
                                                    confirmButtonText: "Yes, delete it!",
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        saveEntries(
                                                            entries?.filter((_entry: Entry) => {
                                                                return entry.id !== _entry.id;
                                                            }) ?? [],
                                                        );
                                                    }
                                                });
                                            }}
                                            className={classNames(
                                                active
                                                    ? "bg-gray-100 text-gray-400 dark:bg-gray-900"
                                                    : "text-gray-700 dark:text-gray-400 ",
                                                "block px-4 py-2 text-sm",
                                            )}
                                        >
                                            Delete
                                        </a>
                                    )}
                                </Menu.Item>
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
            {/* <PrintPre> */}
            {/* {entry} */}
            {/* </PrintPre> */}
            {showEditRules && (
                <RulePicker
                    // rules={allRulesAndGroupsQuery.data ?? []}
                    // enabled={enabledRules ?? []}
                    // setEnabled={setEnabledRules}
                    setAreOr={setRgAreOr}
                    targetEntry={entry}
                    processflowId={processflowId}
                    // andOrOptions={andOrOptions}
                    // selectionOptions={selectionOptions}
                    areOr={rgAreOr}
                    // existing={entries}
                    isStep={false}
                    isStage={false}
                    target={entry.value}
                    show={showEditRules}
                    close={() => setShowEditRules(false)}
                />
            )}
            {showAddEntry && entry.type === "button" && (
                <ButtonCreator
                    entries={entries ?? []}
                    entry={entry}
                    title={entry.field}
                    label="Text"
                    name="entry"
                    field={field}
                    value={value}
                    isEdit={isEdit}
                    saveEdit={saveEdit}
                    show={showAddEntry}
                    close={() => setShowAddEntry(false)}
                    save={addToList}
                />
            )}
            {showAddEntry && entry.type === "valueCalculator" && (
                <ModalValueCalculator
                    entries={entries}
                    entry={entry}
                    title={entry.field}
                    label="Text"
                    name={entry.field}
                    field={entry.field}
                    value={value}
                    isEdit={isEdit}
                    widthOf300Cols={widthOf300Cols}
                    saveEdit={saveEdit}
                    show={showAddEntry}
                    close={() => setShowAddEntry(false)}
                    save={addToList}
                    description={description}
                />
            )}
            {showAddEntry && entry.type === "wysiwyg" && (
                <ModalHtmlEditor
                    entries={entries}
                    entry={entry}
                    title={entry.field}
                    label="Text"
                    name={entry.field}
                    field={entry.field}
                    value={value}
                    isEdit={isEdit}
                    saveEdit={saveEdit}
                    show={showAddEntry}
                    close={() => setShowAddEntry(false)}
                    save={addToList}
                />
            )}
            {showAddEntry && entry.type === "video" && (
                <ModalVideoSelector
                    entry={entry}
                    title={entry.field}
                    label="Text"
                    name="entry"
                    field={field}
                    value={value}
                    isEdit={isEdit}
                    widthOf300Cols={widthOf300Cols}
                    saveEdit={saveEdit}
                    show={showAddEntry}
                    close={() => setShowAddEntry(false)}
                    save={addToList}
                    description={description}
                />
            )}

            {showAddHelp && (
                <ModalHtmlEditor
                    entries={entries}
                    entry={helpEntry}
                    title={"Help Section for " + entry.field}
                    label="Text"
                    name={entry.field}
                    field={entry.field}
                    value={value}
                    isEdit={isEdit}
                    saveEdit={saveEdit}
                    show={showAddHelp}
                    close={() => setShowAddHelp(false)}
                    save={saveEdit}
                    isHelpDocument={true}
                />
            )}
            {showAddEntry &&
                entry.type !== "valueCalculator" &&
                entry.type !== "video" &&
                entry.type !== "wysiwyg" &&
                entry.type !== "button" && (
                    <ModalFieldValue
                        entry={entry}
                        isSelect={entry.type === "selectBox"}
                        title={entry.field}
                        label="Text"
                        name={entry.field}
                        field={entry.field}
                        value={value}
                        isEdit={isEdit}
                        widthOf300Cols={widthOf300Cols}
                        saveEdit={saveEdit}
                        show={showAddEntry}
                        close={() => setShowAddEntry(false)}
                        save={addToList}
                        description={description}
                    />
                )}
        </div>
    );
}
