import { z } from "zod";

export enum MagicLinkTypes {
    clientSignUp = "clientSignUp",
    affiliateSignUp = "affiliateSignUp",
}

export const magicLinkSchema = z.object({
    id: z.string().uuid(),
    createdById: z.string().uuid(),
    type: z.nativeEnum(MagicLinkTypes),
    data: z.record(z.any()),
    code: z.string(),
    usedAt: z.coerce.date().nullish(),
    options: z
        .object({ affiliateUserId: z.string().uuid().optional(), docCollectorId: z.string().uuid().optional() })
        .nullish(),
});

export const magicLinkResponseSchema = magicLinkSchema.extend({
    createdByUser: z
        .object({
            id: z.string().uuid(),
            firstName: z.string(),
            lastName: z.string(),
            email: z.string().email(),
            role: z.string(),
            createdAt: z.coerce.date(),
            updatedAt: z.coerce.date(),
        })
        .optional(),
});

export type MagicLink = z.infer<typeof magicLinkSchema>;
export type MagicLinkResponse = z.infer<typeof magicLinkResponseSchema>;
