import { FetchNextPageOptions } from "@tanstack/react-query";
import { useEffect, useRef, ReactNode } from "react";
import { useIntersection } from "react-use";
import Loader2 from "../sections/utilities/Loader2";

interface Props {
    children: ReactNode;
    isLoading?: boolean;
    onRequestToLoadMore: (options?: FetchNextPageOptions) => void;
}

export const PaginatedView = ({ children, isLoading, onRequestToLoadMore }: Props) => {
    const ref = useRef<HTMLDivElement>(null);

    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: "200px",
        threshold: 0,
    });

    useEffect(() => {
        if (intersection?.isIntersecting && !isLoading) {
            onRequestToLoadMore();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intersection?.isIntersecting, onRequestToLoadMore]);

    return (
        <>
            {children}
            <div ref={ref} />
            {isLoading && <Loader2 />}
        </>
    );
};
