/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import { RoleGroup } from "../typings/api/role-group";
import APIService from "./api.service";

// const debug        = require('debug')('Neogen:CDRService');

// ID values copied from database.
export const roleGroupTypes = {
    SuperUser: 4,
    PublicAccess: 5,
    ERCClient: 6,
    Affiliate: 7,
    Accountant: 8,
    AccountManager: 9,
    Debug: 10,
    Closer: 11,
    AffiliateInTraining: 12,
};

export const roleAssignments = {
    [roleGroupTypes.SuperUser]: [...Object.values(roleGroupTypes)],
    [roleGroupTypes.PublicAccess]: [],
    [roleGroupTypes.ERCClient]: [],
    [roleGroupTypes.Affiliate]: [roleGroupTypes.ERCClient, roleGroupTypes.Affiliate, roleGroupTypes.PublicAccess],
    [roleGroupTypes.Accountant]: [],
    [roleGroupTypes.AccountManager]: [],
    [roleGroupTypes.AffiliateInTraining]: [],
};

class RoleGroupService extends APIService<RoleGroup> {
    constructor() {
        super("role-groups");
    }
    async getAllRoleGroups() {
        const filter = {
            include: ["roles"],
        };
        const response = await this.getFiltered(filter);
        if (response && response.data) {
            response.data = response.data.map((entry) => {
                if (!entry.roles) {
                    entry.roles = [];
                }
                return entry;
            });
        }
        return response;
    }
}

export default new RoleGroupService();
