import { makeRequest, parseSchema } from "../../shared/utils";
import { userSchema } from "../domain/user";
import { userResponseSchema } from "./get-users";

export const triggerAffiliateRegistration = async ({
    userId,
    email,
    name,
    firstName,
    lastName,
    phoneNumber,
    utm,
    affiliateUserId,
    defaultProcessflowId,
}: {
    userId: string;
    email: string;
    firstName: string;
    lastName: string;
    name: string;
    phoneNumber: string;
    utm: string;
    affiliateUserId: string;
    defaultProcessflowId: number;
}) => {
    const response = await makeRequest({
        method: "post",
        path: "/trigger-affiliate-registration",
        data: {
            userId,
            email,
            name,
            firstName,
            lastName,
            phoneNumber,
            utm,
            affiliateUserId,
            defaultProcessflowId,
        },
    });
    const userDto = parseSchema(userResponseSchema, response.data);
    const user = parseSchema(userSchema, userDto);
    return user;
};
