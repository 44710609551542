import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { getMagicLinkByCode } from "../actions/get-magic-link-by-code";
import { MagicLinkTypes } from "../domain/magic-link";
import { ClientMagicLinkSignUpForm } from "../components/client-magic-link-sign-up-form";
import { AffiliateMagicLinkSignUpForm } from "../components/affiliate-magic-link-sign-up-form";

export type MagicLinkDataType = {
    code: string;
    id: string;
    createdById: string;
    type: MagicLinkTypes;
    data: Record<string, any>;
    usedAt?: Date | null | undefined;
    options?:
        | {
              affiliateUserId?: string | undefined;
          }
        | null
        | undefined;
};

export const MagicLinkPage = () => {
    const authToken = getAuthTokenNoThrow() || "no-token";
    const { code } = useParams();

    const query = useQuery(["magic-link"], async () => {
        const magicLink = code ? await getMagicLinkByCode({ authToken, code }) : undefined;
        return magicLink;
    });
    const magicLink: MagicLinkDataType | undefined = query.data;

    return !code || !magicLink ? (
        <div style={{ textAlign: "center", fontSize: 20 }}>Your magic code is invalid. Please contact us for help.</div>
    ) : magicLink?.type === MagicLinkTypes.affiliateSignUp ? (
        <AffiliateMagicLinkSignUpForm magicLink={magicLink} code={code} />
    ) : (
        <ClientMagicLinkSignUpForm magicLink={magicLink} code={code} />
    );
};
