import { useState, useEffect, useMemo, useCallback } from "react";
import { QueryFunction, useInfiniteQuery } from "@tanstack/react-query";
import { debounce } from "lodash";
import { search as ssSearch } from "ss-search";
import PageDescription from "../../../layout/page-description";
import SearchField from "../../../layout/search-field";
import TableNeogen from "../../../layout/table-neogen";
import Main from "../../../layout/Main";
import { usePaginatedResultItems } from "../../../hooks/usePaginatedResults";
import { PaginatedView } from "../../../layout/PaginatedView";
import { CreateClientMagicLinkModal } from "../../../magic-links/components/create-client-magic-link-modal";
import authService from "../../../services/auth.service";
import magicLinksService from "../../../services/magic-links.service";
import { MagicLinkResponse } from "../../../magic-links/domain/magic-link";
import { Navigation } from "../../../typings/api/navigations";

const ITEMS_PER_PAGE = 15;

export default function MagicLinks() {
    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [canIAccess, setCanIAccess] = useState(false);
    const [showMagicLinkModal, setShowMagicLinkModal] = useState(false);
    const [start, setStart] = useState(0);

    const fetchMagicLinks = async ({ pageParam = { hasNextPage: true } }) => {
        try {
            if (!pageParam.hasNextPage) {
                return { pages: [], hasNextPage: false };
            }
            const response = await magicLinksService.getMagicLinks(start, ITEMS_PER_PAGE);
            setStart(start + ITEMS_PER_PAGE);
            return {
                pages: response?.data || [],
                hasNextPage: !!response?.data,
            };
        } catch (error) {
            console.error("Could not fetch magic links");
            throw error;
        }
    };

    const { data, isFetchingNextPage, fetchNextPage, isFetched } = useInfiniteQuery(["magic-links"], fetchMagicLinks, {
        refetchOnMount: false,
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage) => {
            const hasNextPage = lastPage?.hasNextPage || null;
            return { hasNextPage };
        },
    });

    const magicLinkItems = usePaginatedResultItems<
        {
            pages: Navigation[];
            hasNextPage: boolean;
        },
        MagicLinkResponse
    >(data, (response) => {
        // TODO: Need to circle back to this and figure out why it is returning weird magic link data
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return response.pages.pageItems;
    });

    const processedMagicLinks = magicLinkItems.map((magicLink: MagicLinkResponse) => ({
        ...magicLink,
        createdBy: [magicLink.createdByUser?.firstName, magicLink.createdByUser?.lastName].join(" "),
        createdFor: [magicLink.data.firstName, magicLink.data.lastName].join(" "),
    }));

    const magicLinks = useMemo(
        () =>
            debouncedSearch
                ? // TODO: Add more searchable keys when decided upon
                  (ssSearch(processedMagicLinks || [], ["code", "createdBy", "createdFor"], debouncedSearch) as any)
                : processedMagicLinks || [],
        [debouncedSearch, processedMagicLinks],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateDebouncedSearch = useCallback(
        debounce(
            (term) => {
                setDebouncedSearch(term);
            },
            700,
            { trailing: true, maxWait: 1000 },
        ),
        [],
    );

    useEffect(() => {
        updateDebouncedSearch(search);
    }, [search, updateDebouncedSearch]);

    useEffect(() => {
        authService.canIAccess("ADD_USER").then((response) => {
            setCanIAccess(response);
        });
    }, []);

    return (
        <Main>
            {showMagicLinkModal && (
                <CreateClientMagicLinkModal
                    onClose={() => {
                        setShowMagicLinkModal(false);
                    }}
                />
            )}
            <PageDescription
                title="Magic links"
                description="Manage magic links"
                buttons={[
                    {
                        label: "Create Magic Link",
                        onClick: () => setShowMagicLinkModal(true),
                        disabled: !canIAccess,
                        icon: "fas fa-user-plus",
                    },
                ]}
            >
                <SearchField search={search} setSearch={setSearch} placeholder="Search users..." />
            </PageDescription>
            {magicLinks && (
                <PaginatedView onRequestToLoadMore={fetchNextPage} isLoading={isFetchingNextPage}>
                    <TableNeogen
                        entries={magicLinks}
                        ignoreFields={["data", "createdById", "type", "createdByUser", "options"]}
                        formatters={[
                            {
                                field: "createdByUser",
                                type: "DisplayItem",
                                itemToDisplay: "createdByUser.name",
                            },
                            {
                                field: "usedAt",
                                type: "DateTime",
                            },
                        ]}
                    />
                </PaginatedView>
            )}
        </Main>
    );
}
