import { Navigation } from "../typings/api/navigations";
import APIService from "./api.service";

class MagicLinkService extends APIService<Navigation> {
    constructor() {
        super("magic-links");
    }
    getMagicLinks(offset: number, count: number) {
        return this.getSome(offset, count);
    }
}

export default new MagicLinkService();
