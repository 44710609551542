import { FieldEntry } from "../components/field-entry";
//import React, { useState, useEffect, useRef, Fragment } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ProcessFlow } from "../../../typings/api/processflow";
import ModalDialog from "../../../layout/modal-dialog";
import { ProcessFlowStage } from "../../../typings/api/processflow-stage";
import processflowStageService from "../../../services/processflow-stage.service";
import Loader2 from "../../utilities/Loader2";
import SelectNeoGen from "../../../layout/select-neogen";
import processflowService from "../../../services/processflow.service";
import { AddToList, Entry } from "../../../layout/add-to-list";
import InputControlled from "../../../layout/input-controlled";
import TextAreaNeoGenControlled from "../../../layout/text-area-controlled";
import Swal from "sweetalert2";
import { useParams } from "react-router";

import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { SortableItem } from "./sortable-item";
import { Fragment, useState, useRef, useEffect } from "react";

export default function AddEdit(props: AddEditProps) {
    const [parent, setParent] = useState(null);

    const [stage, setStage] = useState<number>(-1);
    const [reliesOnTrue, setReliesOnTrue] = useState<number>(0);
    const [reliesOnFalse, setReliesOnFalse] = useState<number>(0);
    const [inputType, setInputType] = useState<number>(-1);
    const [causesRepContactOnCompletion, setCausesRepContactOnCompletion] = useState<boolean>(false);
    const [checkboxEntries, setCheckboxEntries] = useState<Entry[]>([]);
    const [entries, setEntries] = useState<Entry[]>([]);
    const [textfieldEntries, setTextfieldEntries] = useState<Entry[]>([]);
    const [textAreaEntries, setTextAreaEntries] = useState<Entry[]>([]);
    const [fileUploadEntries, setFileUploadEntries] = useState<Entry[]>([]);
    const [datePickerEntries, setDatePickerEntries] = useState<Entry[]>([]);
    const [yesNoEntries, setYesNoEntries] = useState<Entry[]>([]);
    const [selectBoxEntries, setSelectBoxEntries] = useState<Entry[]>([]);
    const [stateEntries, setStateEntries] = useState<Entry[]>([]);
    const [sendsEmailOnCompletion, setSendsEmailOnCompletion] = useState<boolean>(false);
    const [allEntries, setAllEntries] = useState<Entry[]>([]);
    const [internal, setInternal] = useState<boolean>(false);
    const [optional, setOptional] = useState<boolean>(false);
    const [title, setTitle] = useState<string>("");
    const [details, setDetails] = useState<string>("");
    const [textFieldName, setTextFieldName] = useState<string>("");
    const [internalComments, setInternalComments] = useState<string>("");
    const [currentReliesOnTrue, setCurrentReliesOnTrue] = useState<ProcessFlow | undefined>(undefined);
    const [checkboxEnabler, setCheckboxEnabler] = useState<string>("");
    const [saved, setSaved] = useState<boolean>(false);

    const fieldsSet = useRef(false);
    const cache = useQueryClient();
    const { processflowGroup: id } = useParams();
    const [items, setItems] = useState([1, 2, 3, 4, 5]);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );
    // const { register, handleSubmit, formState: { errors }, watch } = useForm<ProcessFlow>({ resolver });
    // const onSubmit = handleSubmit((data) => {
    // console.log(data);
    // });
    const stageQuery = useQuery(["processflow-stages", "getAllByGroupId", id], async () => {
        const response = await processflowStageService.getAllByGroupIdIncludingRuleGroups(Number(id));
        if (response) {
            return response.data;
        }
    });

    // useEffect(() => {
    //     setInputType(watch("inputType")??"");
    // }, [watch]);
    // console.log(watch("inputType"));
    const entriesQuery = useQuery(
        ["processflow-entries", id, props.show],
        async () => {
            const response = await processflowService.getAllByGroupId(Number(id));
            if (response) {
                const mapped = response.data.map((entry: ProcessFlow) => {
                    entry.stageName =
                        stageQuery.data?.find((stage: ProcessFlowStage) => stage.id === entry.stage)?.name ?? "Unknown";
                    entry.entries = JSON.stringify(
                        JSON.parse(entry.entries ?? "[]").map((e: Entry) => {
                            if (!e.id) {
                                e.id = e.field;
                            }

                            return e;
                        }),
                    );

                    // console.log(JSON.parse(entry.entries));
                    return entry;
                });
                return mapped;
            }
        },
        { enabled: stageQuery.isSuccess },
    );

    useEffect(() => {
        if (props.isEdit && entriesQuery.isSuccess && !fieldsSet.current) {
            fieldsSet.current = true;

            console.log(entriesQuery.data, props.id);
            const entry = entriesQuery.data?.find((entry: any) => entry.id === props.id);
            if (!props.id) {
                alert("no");
            }
            if (entry) {
                setStage(entry.stage);
                setTitle(entry.title);
                setDetails(entry.details ?? "");
                setReliesOnTrue(entry.reliesOnTrue ?? 0);
                setReliesOnFalse(entry.reliesOnFalse ?? 0);
                setInputType(entry.inputType ?? 0);
                setTextFieldName(entry.textfieldName ?? "");
                setFileUploadEntries(JSON.parse(entry.fileUploadEntries ?? "[]"));
                setEntries(JSON.parse(entry.entries ?? "[]"));
                setStateEntries(JSON.parse(entry.stateEntries ?? "[]"));
                setYesNoEntries(JSON.parse(entry.yesNoEntries ?? "[]") ?? []);
                setSelectBoxEntries(JSON.parse(entry.selectBoxEntries ?? "[]") ?? []);
                setDatePickerEntries(JSON.parse(entry.datePickerEntries ?? "[]") ?? []);
                setStateEntries(JSON.parse(entry.stateEntries ?? "[]") ?? []);
                setCheckboxEnabler(entry.checkboxEnabler ?? "");
                setTextAreaEntries(JSON.parse(entry.textareaEntries ?? "[]") as Entry[]);
                setTextfieldEntries(JSON.parse(entry.textfieldEntries ?? "[]") as Entry[]);
                setCausesRepContactOnCompletion(entry.causesRepContactOnCompletion === 1);
                setCheckboxEntries(JSON.parse(entry.checkboxEntries ?? "[]") as Entry[]);
                setFileUploadEntries(JSON.parse(entry.fileUploadEntries ?? "[]") as Entry[]);
                setSendsEmailOnCompletion(entry.sendsEmailOnCompletion === 1);
                setInternal(entry.internalOnly === 1);
                setOptional(entry.optional === 1);
                setInternalComments(entry.internalComments ?? "");
                // setWidthOf300Cols(entry.widthOf300Cols ?? "150");
            }
        }
    }, [props.id, props.isEdit, entriesQuery.data, entriesQuery.isSuccess]);

    function saveEntries(inputEntries: Entry[]) {
        setEntries(inputEntries);
        cache.invalidateQueries(["processflow-entries"]);
    }

    async function save() {
        setSaved(false);
        const entry: ProcessFlow = {
            stage: stage,
            title: title,
            details: details,
            reliesOnTrue: reliesOnTrue ?? -1,
            reliesOnFalse: reliesOnFalse ?? -1,
            inputType: inputType,
            textfieldName: textFieldName,
            causesRepContactOnCompletion: causesRepContactOnCompletion ? 1 : 0,
            entries: JSON.stringify(entries),
            ruleGroupsAreOr: 1, //TODO: Fix this
            sendsEmailOnCompletion: sendsEmailOnCompletion ? 1 : 0,
            selectBoxEntries: JSON.stringify(selectBoxEntries),
            internalOnly: internal ? 1 : 0,
            optional: optional ? 1 : 0,
            internalComments: internalComments,
            checkboxEnabler: checkboxEnabler,
            helpDoc: -1,
            group: Number(id),
        };
        try {
            // let response;
            if (props.isEdit) {
                entry.id = props.id;
                // alert(props.id);
                if (props.id > 0) {
                    await processflowService.update(props.id, entry);
                } else {
                    alert("ID issue");
                }
            } else {
                await processflowService.create(entry);
            }
            props.close();
            cache.invalidateQueries();
            cache.removeQueries();
            setSaved(true);
            Swal.fire({
                title: "Success",
                text: "Entry saved successfully.",
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
            });
        } catch (e) {
            console.error(e);
            Swal.fire({
                title: "Error",
                text: "Error saving entry.",
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
            });
        }
    }

    function renderEntries(existing: any) {
        const newEntries = existing?.map((e: any) => {
            e.name = e.title;
            return e;
        });
        newEntries?.push({ id: 0, name: "None" });
        return newEntries;
    }

    useEffect(() => {
        if (reliesOnTrue > -1) {
            setCurrentReliesOnTrue(entriesQuery.data?.find((e: any) => e.id === reliesOnTrue));
        }
    }, [entriesQuery.data, reliesOnTrue]);

    function handleDragEnd(event: any) {
        const { active, over } = event;
        if (active.id !== over.id) {
            setEntries((items: any) => {
                // const oldIndex = items.indexOf(active.field);
                const oldIndex = items.findIndex((i: any) => i.id == active.id);
                const newIndex = items.findIndex((i: any) => i.id == over.id);

                // const newIndex = items.indexOf(over.field);
                return arrayMove(items, oldIndex, newIndex);
            });
        }
    }
    return (
        <ModalDialog
            title={"Step"}
            show={props.show}
            close={props.close}
            size="md"
            okAction={save}
            okText="Save"
            isSaveDialog={true}
            saved={saved}
        >
            {stageQuery.isLoading ? (
                <Loader2 />
            ) : (
                <Fragment>
                    <div className="grid grid-cols-2 gap-5">
                        <InputControlled label={"Item Title"} value={title} setValue={setTitle} name={"title"} />

                        <SelectNeoGen
                            label={"Stage"}
                            value={stage}
                            onChange={(e: string | number) => setStage(Number(e))}
                            options={stageQuery.data ?? []}
                        />
                        <div className="col-span-2">
                            <TextAreaNeoGenControlled
                                value={details}
                                label={"Details"}
                                setValue={setDetails}
                                name={"details"}
                            />
                        </div>
                        <div className="col-span-2">
                            <TextAreaNeoGenControlled
                                value={internalComments}
                                label={"Internal Comments"}
                                setValue={setInternalComments}
                                name={"internalComments"}
                            />
                        </div>
                    </div>
                    <div className="pb-5">
                        <div className="relative flex  flex-col justify-center py-6 ">
                            <div className="grid grid-cols-4 gap-5">
                                <AddToList
                                    type="checkbox"
                                    icon="fal fa-circle-check fa-2x"
                                    title={"Checkbox"}
                                    entries={entries}
                                    setEntries={setEntries}
                                />

                                <AddToList
                                    type="textfield"
                                    icon="fal fa-input-text fa-2x"
                                    title={"Text Input"}
                                    entries={entries}
                                    setEntries={setEntries}
                                />

                                <AddToList
                                    type="textDisplay"
                                    icon="fal fa-presentation-screen fa-2x"
                                    title={"Text Display"}
                                    entries={entries}
                                    setEntries={setEntries}
                                />

                                <AddToList
                                    type="textarea"
                                    icon="fal fa-pen-field fa-2x"
                                    title={"Text Area"}
                                    entries={entries}
                                    setEntries={setEntries}
                                />

                                <AddToList
                                    type="fileUpload"
                                    icon="fal fa-file-upload fa-2x"
                                    title={"File Upload"}
                                    entries={entries}
                                    setEntries={setEntries}
                                />

                                <AddToList
                                    type="state"
                                    icon="fal fa-map-location fa-2x"
                                    title={"State"}
                                    entries={entries}
                                    setEntries={setEntries}
                                />

                                <AddToList
                                    type="yesNo"
                                    icon="fal fa-circle-question fa-2x"
                                    title={"Yes/No"}
                                    entries={entries}
                                    setEntries={setEntries}
                                />

                                <AddToList
                                    type="selectBox"
                                    icon="fal fa-list-dropdown fa-2x"
                                    isSelect={true}
                                    title={"Select Box"}
                                    entries={entries}
                                    setEntries={setEntries}
                                />

                                <AddToList
                                    type="datePicker"
                                    icon="fal fa-calendar fa-2x"
                                    title={"Date Picker"}
                                    entries={entries}
                                    setEntries={setEntries}
                                />

                                <AddToList
                                    type="valueCalculator"
                                    icon="fal fa-calculator fa-2x"
                                    title={"Value Calculator"}
                                    entries={entries}
                                    setEntries={setEntries}
                                />

                                <AddToList
                                    type="video"
                                    icon="fal fa-video fa-2x"
                                    title={"Video"}
                                    entries={entries}
                                    setEntries={setEntries}
                                />

                                <AddToList
                                    type="wysiwyg"
                                    icon="fal fa-cloud-word fa-2x"
                                    title={"HTML Section"}
                                    entries={entries}
                                    setEntries={setEntries}
                                />

                                <AddToList
                                    type="button"
                                    icon="fal fa-computer-mouse fa-2x"
                                    title={"Button (Next etc)"}
                                    entries={entries}
                                    setEntries={setEntries}
                                />

                                {/* <ButtonNeoGen
                                        text="Import Old"
                                        onClick={importOld}
                                        size="xs"
                                    /> */}
                            </div>

                            <div className="mt-5 columns-1  gap-5 [column-fill:_balance] box-border w-full mx-auto before:box-inherit after:box-inherit">
                                <DndContext
                                    sensors={sensors}
                                    collisionDetection={closestCenter}
                                    onDragEnd={handleDragEnd}
                                >
                                    <SortableContext items={entries} strategy={verticalListSortingStrategy}>
                                        {entries.map((entry: any, idx: any) => (
                                            <SortableItem
                                                key={entry.field}
                                                id={entry.field}
                                                value={
                                                    <FieldEntry
                                                        key={idx}
                                                        entry={entry}
                                                        entries={entries}
                                                        saveEntries={saveEntries}
                                                        idx={idx}
                                                        processflowId={Number(props.id)}
                                                    />
                                                }
                                            />
                                        ))}
                                    </SortableContext>
                                </DndContext>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-5">
                        <div className="col-span-1">
                            <SelectNeoGen
                                label={"Input is Optional"}
                                options={[
                                    { id: 1, name: "Yes" },
                                    { id: 0, name: "No" },
                                ]}
                                value={optional ? 1 : 0}
                                onChange={(e: string | number) => setOptional(e.toString() === "1")}
                            />
                        </div>
                        <div className="col-span-1">
                            <SelectNeoGen
                                label={"Internal Only"}
                                options={[
                                    { id: 1, name: "Yes" },
                                    { id: 0, name: "No" },
                                ]}
                                value={internal ? 1 : 0}
                                onChange={(e: string | number) => setInternal(e.toString() === "1")}
                            />
                        </div>

                        <div className="col-span-1">
                            <SelectNeoGen
                                label={"Sends Email On Completion"}
                                options={[
                                    { id: 1, name: "Yes" },
                                    { id: 0, name: "No" },
                                ]}
                                value={sendsEmailOnCompletion ? 1 : 0}
                                onChange={(e: string | number) => setSendsEmailOnCompletion(e.toString() === "1")}
                            />
                        </div>
                        <div className="col-span-1">
                            <SelectNeoGen
                                label={"Causes Rep Contact On Completion"}
                                options={[
                                    { id: 1, name: "Yes" },
                                    { id: 0, name: "No" },
                                ]}
                                value={causesRepContactOnCompletion ? 1 : 0}
                                onChange={(e: string | number) => setCausesRepContactOnCompletion(e.toString() === "1")}
                            />
                        </div>
                    </div>
                </Fragment>
            )}
        </ModalDialog>
    );
}

type AddEditProps = {
    show: boolean;
    close: () => void;
    isEdit: boolean;
    id: number;
};
