import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { ChangeEvent } from "react";

export default function SearchField({
    search,
    setSearch,
    placeholder,
    customBgColor,
    onChange,
    onFocus,
    onBlur,
    hideMagifyingIcon,
}: {
    search: string;
    setSearch: (e: string) => void;
    placeholder?: string;
    customBgColor?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    hideMagifyingIcon?: boolean;
}) {
    return (
        <div>
            <div className=" flex rounded-md shadow-sm">
                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                    {hideMagifyingIcon ? null : (
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                    )}
                    <input
                        type="text"
                        autoComplete="off"
                        className={
                            customBgColor
                                ? customBgColor
                                : "block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-900"
                        }
                        placeholder={placeholder || "Search...."}
                        value={search}
                        onChange={(e) => {
                            setSearch(e.currentTarget.value);
                            if (onChange) {
                                onChange(e);
                            }
                        }}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                </div>
            </div>
        </div>
    );
}
