/**
 *
 * @param {string} number
 */
import { parsePhoneNumberFromString } from "libphonenumber-js";
export function formatPhonenumber(number?: string, country: any = "US") {
    try {
        const response = parsePhoneNumberFromString(number ?? "", country);
        return response?.formatNational();
    } catch (e) {
        return number ?? "";
    }
}

export function formatEmail(email?: string, truncate = true) {
    if ((truncate && email?.length) ?? 0 > 30) {
        return `${email?.substring(0, 30)}...`;
    }
    return email?.toLowerCase().trim();
}

export function formatPrice(number?: number) {
    return new Intl.NumberFormat(undefined, {
        style: "currency",
        currency: "USD",
    }).format(number ?? 0);
}

/**
 * Formats seconds (number) to H:i:s format.
 * 00:12:00
 *
 * When "short" option is set to true, will return:
 * 0:50
 * 2:00
 * 12:00
 * 1:00:24
 * 10:00:00
 */
export default function secToHMS(seconds: number, short = false) {
    const pad = (num: number) => (num < 10 ? `0${num}` : num);

    const H = pad(Math.floor(seconds / 3600));
    const i = pad(Math.floor((seconds % 3600) / 60));
    const s = pad(seconds % 60);

    if (short) {
        let result = "";
        if (H > 0) result += `${+H}:`;
        result += `${H > 0 ? i : +i}:${s}`;
        return result;
    } else {
        return `${H}:${i}:${s}`;
    }
}

// Function to convert bytes into human readable format
export function bytesToSize(bytes: number, precision = 2) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / 1024 ** i).toFixed(precision))} ${sizes[i]}`;
}
