import { useMemo } from "react";
import { InfiniteData } from "@tanstack/react-query";

/**
 * @param data - data from useInfiniteQuery
 * @param getPageItems - A function that takes in a page of data and returns an array of items to be included in the paginated query
 * @returns A list of all items to be included in the paginated query
 * @description This hook can be used to retrieve a list of all items from all pages of a paginated query obtained using useInfiniteQuery.
 * @description You have to pass in the data from useInfiniteQuery and a function that returns the items from that data
 * @description The `getPageItems` function takes in a page of data and should return an array of items to be included in the final result. The data passed into getPageItems is a page of data from the data object returned by useInfiniteQuery.
 */
function usePaginatedResultItems<T, V>(data: InfiniteData<T> | undefined, getPageItems: (page: T) => V[]): V[] {
    return useMemo(() => {
        const items: V[] = [];
        const pages = data?.pages.reduce((acc, page) => [...acc, ...getPageItems(page)], items);

        return pages ?? items;
    }, [data?.pages, getPageItems]);
}

export { usePaginatedResultItems };
