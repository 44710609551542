import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import Feed from "../../../layout/feed";
import userService from "../../../services/user.service";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ButtonNeoGen from "../../../layout/button";
import { Link, NavLink } from "react-router-dom";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import { getProcessflowProgresses } from "../../../jason-proof-of-concept/processflow-progresses/actions/get-processflow-progresses";
import { getDataFromProgressData } from "../../../jason-proof-of-concept/wizard/wizard-container";
import { getEstimatedPayoutFromData } from "../../../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { Currency } from "../../../billing/components";

export default function BasicCard({ user, setUser }: { user?: ClearERCUser; setUser: (user: ClearERCUser) => void }) {
    const userId = user?.id || "no-id";
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const [phoneNumber, setPhoneNumber] = useState<string>(user?.phone ?? "Unknown");
    const [editingPhoneNumber, setEditingPhoneNumber] = useState<boolean>(false);
    const cache = useQueryClient();

    useEffect(() => {
        if (
            user?.phone !== phoneNumber &&
            user?.phone !== undefined &&
            phoneNumber !== undefined &&
            phoneNumber !== "" &&
            phoneNumber !== "Unknown"
        ) {
            console.log("phone number changed from " + user?.phone + " to " + phoneNumber);
            if (user.id) {
                if (!user.realm) {
                    user.realm = "clear-erc";
                }
                user.phone = phoneNumber;
                userService.update(user.id ?? "asdasd", user).then((res) => {
                    console.log(res);
                });
            }
            console.error(1);
            setUser(user);
            setPhoneNumber(user?.phone ?? "");
            cache.invalidateQueries(["user", user?.id]);

            // setEditingPhoneNumber(false);
        } else if (user?.phone && phoneNumber === "Unknown") {
            setPhoneNumber(user.phone);
        }
    }, [cache, phoneNumber, setUser, user, user?.phone]);

    const query = useQuery(["user-summary-data", userId, authToken], async () => {
        const group = 7;
        const [usersProgress] = await getProcessflowProgresses({
            authToken,
            filters: { userId, group },
        });
        const data = getDataFromProgressData(usersProgress.data as any);
        const w22020 = data["Average full time W2 Employees in 2020?"];
        const w22021 = data["Average full time W2 Employees in 2021?"];
        const estimatedAmount = getEstimatedPayoutFromData({ data });
        return { estimatedAmount, w22020, w22021 };
    });
    const estimatedAmount = query?.data?.estimatedAmount;
    const w22020 = query?.data?.w22020;
    const w22021 = query?.data?.w22021;

    return (
        <>
            <div
                style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
            >
                <h1 className="text-3xl font-bold tracking-tight text-blue-gray-900">Basic Information</h1>
                {user && (
                    <NavLink
                        to={`/users/${user.id}/application`}
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <span className="fa fa-folder-open mr-3"></span>
                        Open Application
                    </NavLink>
                )}
            </div>
            <form className="divide-y-gray-200 mt-6 space-y-8 divide-y dark:divide-y-gray-900">
                {/* <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                    <div className="sm:col-span-6">
                        <h2 className="text-xl font-medium text-blue-gray-900">Profile</h2>
                        <p className="mt-1 text-sm text-blue-gray-500">
                            This information will be displayed publicly so be careful what you share.
                        </p>
                    </div>
                </div> */}
                <div className="mt-5 border-t border-gray-200 dark:border-gray-900">
                    <dl className="divide-y divide-gray-200 dark:border-gray-900 dark:divide-gray-900">
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">Company Name</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-300">
                                <span className="flex-grow">{user?.name}</span>
                                <span className="ml-4 flex-shrink-0">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-gray-800"
                                    >
                                        Update
                                    </button>
                                </span>
                            </dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">Contact name</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-300">
                                <span className="flex-grow">
                                    {(user?.firstName ?? "") + " " + (user?.lastName ?? "")}
                                </span>
                                <span className="ml-4 flex-shrink-0">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-gray-800"
                                    >
                                        Update
                                    </button>
                                </span>
                            </dd>
                        </div>

                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">Email address</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-300">
                                <span className="flex-grow">
                                    <a href={"mailto:" + user?.email}>{user?.email}</a>
                                </span>
                                <span className="ml-4 flex-shrink-0">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-gray-800"
                                    >
                                        Update
                                    </button>
                                </span>
                            </dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 items-center">
                            <dt className="text-sm font-medium text-gray-500">Phone Number</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-300 items-center h-6">
                                <span className="flex-grow mt-0">
                                    {editingPhoneNumber ? (
                                        <PhoneInput
                                            inputClass="w-full relative rounded-md mt-1"
                                            placeholder="Enter phone number"
                                            value={phoneNumber}
                                            country={"us"}
                                            onChange={(e164) => setPhoneNumber((e164 ?? "")?.toString())}
                                        />
                                    ) : (
                                        // <InputControlled
                                        //     noMargin
                                        //     value={phoneNumber}
                                        //     setValue={(value) => {
                                        //         setPhoneNumber(value);
                                        //     }}
                                        // />

                                        <ButtonNeoGen
                                            type="info"
                                            onClick={() => {
                                                setEditingPhoneNumber((c) => !c);
                                            }}
                                            icon={"fal fa-edit"}
                                        >
                                            Edit {user?.phone}
                                        </ButtonNeoGen>
                                    )}
                                </span>
                                <span className="ml-4 flex-shrink-0  mt-0">
                                    {editingPhoneNumber ? (
                                        <div className="self-top my-0">
                                            <ButtonNeoGen
                                                onClick={() => {
                                                    setEditingPhoneNumber((c) => !c);
                                                }}
                                            >
                                                Done
                                            </ButtonNeoGen>
                                        </div>
                                    ) : (
                                        <ButtonNeoGen
                                            onClick={() => {
                                                window.open("tel:" + user?.phone, "_self");
                                            }}
                                            icon={"fal fa-phone"}
                                        >
                                            Call Number
                                        </ButtonNeoGen>
                                    )}
                                </span>
                            </dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">W2 Employees 2020</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-300">
                                <span className="flex-grow">{w22020 || "-"}</span>
                            </dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">W2 Employees 2021</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-300">
                                <span className="flex-grow">{w22021 || "-"}</span>
                            </dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">Estimated deal value</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-300">
                                <span className="flex-grow">
                                    {estimatedAmount ? <Currency amount={estimatedAmount} /> : "-"}
                                </span>
                            </dd>
                        </div>
                    </dl>
                    <Feed />
                </div>
                {/* <CustomerCard /> */}
            </form>
        </>
    );
}
