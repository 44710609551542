import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { z } from "zod";
import userAtom from "../../atoms/userAtom";
import { Form, FormModalButtons } from "../../layout/form/form";
import { TextField } from "../../layout/form/text-field";
import { useForm } from "../../hooks/useForm";
import ButtonNeoGen from "../../layout/button";
import ModalDialog from "../../layout/modal-dialog";
import ErrorSection from "../../layout/error-section";
import { createMagicLink, CreateMagicLinkData } from "../actions/create-magic-link";
import { MagicLink, MagicLinkTypes } from "../domain/magic-link";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import usersService from "../../services/users.service";
import { Search } from "../../layout/form/searchField";
import { SelectField } from "../../layout/form/selectField";

const schema = z.object({
    firstName: z.string().nullish(),
    lastName: z.string().nullish(),
    companyName: z.string().nullish(),
    email: z.string().email().nullish(),
    phone: z.string().nullish(),
    w2Employees2020: z.string().optional(),
    w2Employees2021: z.string().optional(),
    affiliateId: z.string().optional(),
    docCollectorId: z.string().optional(),
});

type Data = z.infer<typeof schema>;

export const CreateClientMagicLinkModal = ({ onClose }: { onClose: () => any }) => {
    const [loggedInUser, setUser] = useRecoilState(userAtom);
    const authToken = getAuthTokenNoThrow() || "no-token";
    const [magicLink, setMagicLink] = useState<MagicLink | undefined>();
    const [hasCopied, setHasCopied] = useState(false);

    useEffect(() => {
        if (hasCopied) {
            setTimeout(() => setHasCopied(false), 2000);
        }
    }, [hasCopied]);

    const form = useForm({
        schema,
        defaultValues: {},
    });

    const magicLinkMutation = useMutation({
        mutationFn: async ({ authToken, data }: { authToken: string; data: CreateMagicLinkData }) => {
            return await createMagicLink({ authToken, data });
        },
    });

    const handleSubmit = async (data: Data) => {
        try {
            const magicLink = await magicLinkMutation.mutateAsync({
                authToken,
                data: {
                    type: MagicLinkTypes.clientSignUp,
                    data,
                    createdById: loggedInUser.id || "no-userId",
                    options: {
                        affiliateUserId: data.affiliateId || undefined,
                        docCollectorId: data.docCollectorId || undefined,
                    },
                },
            });
            setMagicLink(magicLink);
        } catch (error: any) {
            if (
                error.statusCode === 409 &&
                error.message === "User with this email address already exists and is verified"
            ) {
                form.setError("email", error.message);
            }
            throw error;
        }
    };

    const magicLinkLink = magicLink ? `${window.location.origin}/magic/${magicLink.code}` : undefined;

    const affiliatesQuery = useQuery(["users", "affiliates", "magicLink"], async () => {
        const response = await usersService.getAll({ filters: { role: "Affiliate" } });
        return response?.data || [];
    });
    const affiliates = useMemo(() => affiliatesQuery.data || [], [affiliatesQuery.data]);

    const usersQuery = useQuery(["users", "magicLink"], async () => {
        const response = await usersService.getAll();
        return response?.data || [];
    });
    const users = useMemo(() => usersQuery.data || [], [usersQuery.data]);

    return (
        <ModalDialog
            size="sm"
            show={true}
            close={onClose}
            title={magicLink ? "" : "New Client Magic Link"}
            showCancel={false}
            showOk={false}
        >
            {magicLink ? (
                <div>
                    <h2 className="mb-4 text-center">Magic Link created</h2>
                    <p className="text-center text-gray-400 mt-2 text-sm mb-2">
                        Share this Magic Link with the client so that they can set up their ClearERC account.
                    </p>
                    {magicLinkLink && (
                        <div className="rounded-xl p-3 bg-gray-100 text-center">
                            <a style={{ marginRight: 5 }} href={magicLinkLink}>
                                {magicLinkLink}
                            </a>
                            <ButtonNeoGen
                                type="primary"
                                size="xs"
                                onClick={() => {
                                    navigator.clipboard.writeText(magicLinkLink);
                                    setHasCopied(true);
                                }}
                            >
                                {hasCopied ? "Copied!" : "Copy"}
                            </ButtonNeoGen>
                        </div>
                    )}
                    <div className="mt-8 flex flex-1">
                        <ButtonNeoGen className="w-full" onClick={onClose} type="outline">
                            Dismiss
                        </ButtonNeoGen>
                    </div>
                </div>
            ) : (
                <Form onSubmit={form.handleSubmit(handleSubmit)}>
                    <>
                        {magicLinkMutation.error && (
                            <ErrorSection
                                errors={[(magicLinkMutation.error as any)?.message || "Something went wrong"]}
                            />
                        )}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flex: 1,
                                justifyContent: "space-between",
                                gap: 20,
                            }}
                        >
                            <div style={{ flex: 1 }}>
                                <TextField label="First Name" {...form.getFieldProps("firstName")} />
                            </div>
                            <div style={{ flex: 1 }}>
                                <TextField label="Last Name" {...form.getFieldProps("lastName")} />
                            </div>
                        </div>
                        <TextField label="Company name" {...form.getFieldProps("companyName")} />
                        <TextField
                            label="Email Address"
                            helperText="Magic Link will be sent to this email address."
                            {...form.getFieldProps("email")}
                        />
                        <TextField
                            label="Phone Number"
                            helperText="Magic Link will be sent to this phone number."
                            {...form.getFieldProps("phone")}
                        />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flex: 1,
                                justifyContent: "space-between",
                                gap: 20,
                            }}
                        >
                            <div style={{ flex: 1 }}>
                                <TextField
                                    label="Estimated W2 Employees 2020"
                                    {...form.getFieldProps("w2Employees2020")}
                                />
                            </div>
                            <div style={{ flex: 1 }}>
                                <TextField
                                    label="Estimated W2 Employees 2021"
                                    {...form.getFieldProps("w2Employees2021")}
                                />
                            </div>
                        </div>
                        <Search
                            label="Affiliate"
                            options={affiliates.map((user) => ({
                                value: user.id || "no-id",
                                label: `${[user.firstName, user.lastName].join(" ")} (${user.email})`,
                            }))}
                            {...form.getFieldProps("affiliateId")}
                            placeholder="Search from the list of Affiliates..."
                        />
                        <Search
                            label="Doc Collector"
                            options={users.map((user) => ({
                                value: user.id || "no-id",
                                label: `${[user.firstName, user.lastName].join(" ")} (${user.email})`,
                            }))}
                            {...form.getFieldProps("docCollectorId")}
                            placeholder="Search from the list of Doc Collectors..."
                        />
                        <FormModalButtons
                            form={form}
                            onCancel={onClose}
                            okText="Create Magic Link"
                            isLoading={magicLinkMutation.isLoading}
                        />
                    </>
                </Form>
            )}
        </ModalDialog>
    );
};
